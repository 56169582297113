import React from 'react'
import Layout from '../components/Layout'
import events from '../assets/events.json'
import EventItem from '../components/EventItem';
import Collapsible from '../components/Collapsible';
import { organizeEvents } from '../utils/eventUtils';


const Events = ({ location }) => {
  const { futureEvents, pastEvents } = organizeEvents(events)
  return (
    <Layout location={location} lang={'en'}>
      <section id="one">
        <h2>
          Upcoming Events
        </h2>
        <p>
          In this section, you will find the upcoming workshops that will take place. For any inquiries, do not hesitate to contact us at <a href="mailto:musicoterapia@saracillan.com">musicoterapia@saracillan.com</a>.
        </p>

        {futureEvents.length ?
          futureEvents.map((event) => (
            <EventItem key={event.id} {...event} lang={'en'} />
          ))
          : (
            'There are currently no scheduled workshops'
          )}
      </section>

      <section id="two">
        <Collapsible openedButtonText={'See past events'} closedButtonText={'Hide past events'}>
          {pastEvents.map((event) => (
            <EventItem key={event.id} {...event} lang={'en'} />
          ))}
        </Collapsible>
      </section>
    </Layout>
  )
}

export default Events
